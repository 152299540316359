import React from "react";
import Post from "./post.js";
 
const PostTemplate = (props) => {
  const { domNode, selector } = props;
  const newProps = {
    pagination: props.pagination,
    oneLastNews: props.oneLastNews
  };
  if (!domNode.children.length) {
    return (<></>);
  }
  let postChildren = domNode.children[0].children; //li level
  if (postChildren[0] &&
      selector(postChildren[0], "div", /wp-container-\w+/)) {
    newProps.className = postChildren[0].attribs.class;
    postChildren = postChildren[0].children;
  }
  
  return (<>
    {
      props?.subQueries?.length 
      ? props.subQueries[0]?.posts?.nodes?.map((e,i) => {
          try {
            return (<Post index={i+1} key={ e.id } {...newProps} {...e} >{postChildren}</Post>);
          } catch(error) {
            console.error(error);
            return (<></>);
          }
        })
      : props.posts?.map((e,i) => {
        try {
          return (<Post index={i+1} key={ e.id } {...newProps} {...e} >{postChildren}</Post>);
        } catch(error) {
          console.error(error);
          return (<></>);
        }
      })
    }
  </>);
};

export default PostTemplate;
